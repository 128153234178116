import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import styles from './MainFooter.module.css';
import Divider from "../../components/utils/Divider";
import LogoDark from '../../images/global/logo-dark.png'

const MainFooter = () => {
    return (
        <Fragment>
            <hr />
            <Row>
                <Col lg={{size: 2, offset: 1}} md={{size: 4, offset: 4}} sm={{size: 4, offset: 4}} xs={{size: 4, offset: 4}}>
                    <img src={LogoDark} className={styles.Logo} alt={'SK Logo (dark variant)'} />
                </Col>
                <Col>
                    <p>SK Heating & Cooling Ltd t/a SK Coldstores <Divider /> 229 Bristol Road <Divider /> Gloucester <Divider /> GL1 5TL
                        <br />
                        T: 01452 410500 <Divider /> E: info@skcoldstores.co.uk
                        <br />
                    <span className={styles.Footer}>
                        Company Number: 03604975 | VAT Number: 713484343
                    </span>
                    </p>
                </Col>
            </Row>
        </Fragment>
    )
}

export default MainFooter
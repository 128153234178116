import React from 'react';
import {Row, Col} from 'reactstrap';
import {Helmet} from 'react-helmet';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faList, faMapPin, faPhone} from "@fortawesome/free-solid-svg-icons";
import ContactFormModal from "../components/global/ContactFormModal";

const Contact = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us - SK Coldstores :: Gloucestershire Coldstore Experts</title>
                <meta name="description" content="Coldstore experts in the south west." />
                <link rel="canonical" href="https://skcoldstores.co.uk/contact" />
            </Helmet>
            <Row>
                <Col>
                    <h2 style={{paddingTop: '30px'}}>Contact Us</h2>
                    <p>Get in touch with the SK team using the details below.</p>
                    <h4><FontAwesomeIcon icon={faList} /> Contact Form</h4>
                    <div style={{marginBottom: '10px'}}>
                        <ContactFormModal btnSize={'sm'} btnColor={'primary'}/>
                    </div>
                    <h4><FontAwesomeIcon icon={faPhone} /> Telephone</h4>
                    <p><a href={'tel:01452410500'}>01452 410500</a> option 3</p>
                    <h4><FontAwesomeIcon icon={faEnvelope} /> Email</h4>
                    <p><a href={'mailto:info@skcoldstores.co.uk'}>info@skcoldstores.co.uk</a></p>
                    <h4><FontAwesomeIcon icon={faMapPin} /> Address</h4>
                    SK Heating and Cooling Ltd, 229 Bristol Road, Gloucester, GL1 5TJ
                </Col>
                <Col>
                    <iframe
                        title={'google map'}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2464.631792201102!2d-2.2602254845694247!3d51.84941999345966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48710615772eea39%3A0x9466fcc74aa5ac04!2sSK%20Heating%20and%20Cooling!5e0!3m2!1sen!2suk!4v1644669512947!5m2!1sen!2suk"
                        width="600" height="450" style={{border:'0', paddingTop: '20px'}} allowFullScreen="" loading="lazy" />
                </Col>
            </Row>
        </>
    )
}

export default Contact
import Axios from 'axios';

async function SubmitContactForm(data) {
    const URL = `${process.env.REACT_APP_AWS_API_URL}/contactform`;
    const config = {
        headers: {
            'X-API-Key': process.env.REACT_APP_AWS_API_KEY
        }
    }
    return await Axios.post(URL, data, config)
        .catch((error) => {
            throw new Error(error)
        })
}

export default SubmitContactForm
import React from 'react';
import {Container, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import styles from "./MainHeader.module.css";
import Logo from '../../images/global/SK-Logo.png';
import MenuItems from "../../components/header/MenuItems";
import image1 from '../../images/header/backgroundImages/1.jpg'
import image2 from '../../images/header/backgroundImages/2.png'
import image3 from '../../images/header/backgroundImages/3.png'

const MainHeader = () => {
    const randomBackground = () => {
        const backgroundArray = [image1, image2, image3]
        const random = Math.floor(Math.random() * backgroundArray.length);

        console.log(random)
        return backgroundArray[random];
    }
    return (
        <div className={styles.HeaderBG} >
            <Container className={styles.Container}>
                <Row>
                    <Col sm={12} md={12} lg={4}>
                        <Link to={'/'}><img src={Logo} alt={'SK Coldstores'} className={styles.Logo}/></Link>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <Row>
                            <Col>
                                <MenuItems />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div className={styles.BlueBar} style={{backgroundImage: `url(${randomBackground()})`}}/>
        </div>
    )
}

export default MainHeader
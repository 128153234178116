import React from 'react';
import {Row, Col} from 'reactstrap';
import {Helmet} from 'react-helmet';
import QuickLinks from "../components/global/QuickLinks";
import Home1 from "../images/pages/Home1.png";
import Home2 from "../images/pages/Home2.png";

const HomePage = () => {
    return (
        <div className={'contentContainer'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SK Coldstores :: Gloucestershire Coldstore Experts</title>
                <meta name="description" content="We provide commercial refrigeration and coldstore services to companies throughout the UK primarily focused on the Southwest, Midlands and South Wales." />
                <link rel="canonical" href="https://skcoldstores.co.uk" />
            </Helmet>
            <Row>
                <Col>
                    <h1>
                        Welcome to SK Cold Stores
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={7}>
                    <h2>
                        We are your leading cold store experts!
                    </h2>
                    <p>At SK Heating & Cooling Ltd we understand the importance of having an efficiently running coldstore and how an inefficient coldstore could negatively impact your business. We also know
                    how important it is for your cold storage to adhere to all the health and safety regulations of your industry.</p>
                    <p>That is why SK Heating & Cooling Ltd not only offer new coldstore installation, we also offer coldstore maintenance and repairs on your existing walk in chillers, freezers and coldstore doors. We will always advise the best solution for your business’s needs.</p>
                    <p>We employ leading experts within the industry that can advise on a solution for you, tailored to your individual needs. We work in partnership with our clients every step of the way.</p>
                    <p>We can design, install, commission, and maintain temperature-controlled solutions to suit your requirements including industrial water chillers, walk in chiller/freezer coldstores, mortuary suites/chambers, refrigerated food preparation rooms, glass fronted gravity fed display chillers.</p>
                    <p>We provide commercial refrigeration and coldstore services to companies throughout the UK primarily focused on the Southwest, Midlands and South Wales. We work for both local and national companies offering a fast, efficient and reliable service.</p>
                    <p>
                        Each of our projects are bespoke, we work for a wide variety of clientele, large and small, in a variety of sectors including:
                        <ul>
                            <li>Schools, Colleges & Universities</li>
                            <li>Food Manufacturing</li>
                            <li>Distribution Centres</li>
                            <li>Industrial Units</li>
                            <li>Onsite Catering</li>
                            <li>Hospitals</li>
                            <li>Hospitality</li>
                            <li>Retail</li>
                            <li>Laboratories</li>
                            <li>Temperature controlled manufacturing</li>
                            <li>IT suites</li>
                            <li>Mortuaries</li>
                        </ul>
                    </p>
                </Col>
                <Col>
                    <img src={Home1} alt={'SK Cold Stores'} style={{width: '100%'}} />
                    <br /><br />
                    <img src={Home2} alt={'SK Cold Stores'} style={{width: '100%'}} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <QuickLinks />
                </Col>
            </Row>
        </div>
    )
}

export default HomePage
import React from 'react';
import {Routes, Route} from "react-router-dom";
import HomePage from "../pages/HomePage";
import Service from "../pages/Service";
import Installation from "../pages/Installation";
import Contact from "../pages/Contact";

const MainRoutes = () => {
    return (
        <Routes>
            <Route exact path={'/'} element={<HomePage />}></Route>
            <Route exact path={'/service'} element={<Service />}></Route>
            <Route exact path={'/install'} element={<Installation />}></Route>
            <Route exact path={'/contact'} element={<Contact />}></Route>
        </Routes>
    )
}

export default MainRoutes
import React from 'react';
import styles from './CallToAction1.module.css';
import {Container, Row, Col, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faEnvelope, faPhoneVolume} from '@fortawesome/free-solid-svg-icons';

const CallToAction1 = () => {
    return (
        <div className={styles.CTABody}>
            <Container>
                <div className={styles.CTAContainer}>
                    <Row>
                        <Col md={{offset: 1, size: 'auto'}}>
                            <h4><FontAwesomeIcon icon={faPhone} /> Contact Us</h4>
                            <p>Need to get in touch? We are available by phone and email to answer any questions you have.</p>
                        </Col>
                        <Col md={{offset: 1, size: 4}} style={{paddingTop: '15px'}}>
                            <a href={'tel:01452410500'}><Button size={'sm'}><FontAwesomeIcon icon={faPhoneVolume} fixedWidth /> 01452 410500</Button></a> <a href={'mailto:info@skcoldstores.co.uk'}><Button size={'sm'}><FontAwesomeIcon icon={faEnvelope} fixedWidth /> info@skcoldstores.co.uk</Button></a>
                        </Col>
                    </Row>
                </div>
            </Container>

        </div>
    )
}

export default CallToAction1
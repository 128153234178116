import React, {useState} from 'react';
import {Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faWrench, faPencilRuler, faPhone} from "@fortawesome/free-solid-svg-icons";

const MenuItems = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <Navbar dark expand="lg" style={{textAlign: 'right'}}>
            <NavbarToggler onClick={toggle} className={'ml-auto'} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="justify-content-end" style={{width: '100%'}} navbar>
                    <NavItem>
                        <NavLink tag={Link} to={'/'}><FontAwesomeIcon icon={faHome} /> Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to={'/service'}><FontAwesomeIcon icon={faWrench} /> Service & Maintenance</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to={'/install'}><FontAwesomeIcon icon={faPencilRuler} /> Design & Installation</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to={'/contact'}><FontAwesomeIcon icon={faPhone} /> Contact Us</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    )
}

export default MenuItems
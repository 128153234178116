import React, {useState} from 'react';
import {Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faSpinner, faCheck} from "@fortawesome/free-solid-svg-icons";
import SubmitContactForm from "../../functions/SubmitContactForm";

const ContactFormModal = (props) => {
    const {btnSize, btnColor} = props;
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false});
    const [success, setSuccess] = useState(false)
    const [formData, setFormData] = useState({})

    const OnChange = (event) => {
        const target = event.target;
        setFormData((prevState) => ({...prevState, [target.name]: target.value}));
    }

    const OnSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true)
            await SubmitContactForm(formData);
            setSuccess(true)
        }
        catch (e) {
            console.log(e)
            setMessage({show: true, color: 'danger', message: 'There was an error submitting the form. Please check the data and try again.'})
        }
        finally {
            setLoading(false)
        }
    }

    const toggle = () => {setOpen(!isOpen); setFormData({})}


    return (
        <>
            <Button size={btnSize || 'md'} color={btnColor || 'secondary'} onClick={toggle}><FontAwesomeIcon icon={faList} /> Contact Form</Button>
            <Modal isOpen={isOpen} size={'xl'}>
                <ModalHeader>
                    <FontAwesomeIcon icon={faList} /> Contact Form
                </ModalHeader>
                <ModalBody>
                    {
                        message.show && <Alert color={message.color}>{message.message}</Alert>
                    }
                    {
                        success ?
                            <Alert color={'success'}><FontAwesomeIcon icon={faCheck} /> Thank you! Your contact form has been submitted and we will be in touch as soon as possible.</Alert>
                            :
                            <>
                                <p>Please fill out the form below to allow us to understand your requirements and ensure the best quotation.</p>
                                <Form onSubmit={OnSubmit}>
                                    <FormGroup>
                                        <Label for="CompanyName">Company Name:</Label>
                                        <Input type="text" name="CompanyName" id="CompanyName"
                                               placeholder="Company Name" disabled={loading} onChange={OnChange}
                                               required/>
                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="ContactName">Contact Name:</Label>
                                                <Input type="text" name="ContactName" id="ContactName"
                                                       placeholder="Contact Name" disabled={loading} onChange={OnChange}
                                                       required/>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="EmailAddress">Email Address:</Label>
                                                <Input type="email" name="EmailAddress" id="EmailAddress"
                                                       placeholder="contact@yourcompany.com" disabled={loading}
                                                       onChange={OnChange} required/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="PostCode">Post Code:</Label>
                                                <Input type="text" name="PostCode" id="PostCode" placeholder="GL1 5TL"
                                                       disabled={loading} onChange={OnChange} required/>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="PhoneNumber">Phone Number:</Label>
                                                <Input type="text" name="PhoneNumber" id="PhoneNumber"
                                                       placeholder="01452 410500" disabled={loading} onChange={OnChange}
                                                       required/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup tag="fieldset">
                                        <legend>Enquiry:</legend>
                                        <Row>
                                            <Col sm={3}>
                                                <Label>Enquiry Type</Label>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="EnquiryType"
                                                               value={'New Installation'} disabled={loading}
                                                               onChange={OnChange}/>{' '} New Installation
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="EnquiryType" value={'Repair'}
                                                               disabled={loading} onChange={OnChange}/>{' '} Repair
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="EnquiryDescription">Enquiry</Label>
                                                    <Input type="textarea" name="EnquiryDescription"
                                                           id="EnquiryDescription" disabled={loading}
                                                           placeholder={'Insert your enquiry here'}
                                                           onChange={OnChange}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            {
                                                loading ?
                                                    <Button type={'submit'} color={'primary'} size={'sm'} disabled
                                                            block><FontAwesomeIcon icon={faSpinner}
                                                                                   spin/> Loading</Button>
                                                    :
                                                    <Button type={'submit'} color={'primary'} size={'sm'}
                                                            block><FontAwesomeIcon icon={faCheck}/> Submit</Button>
                                            }

                                        </Col>
                                    </Row>
                                </Form>
                            </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button size={'sm'} onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ContactFormModal
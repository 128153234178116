import React from 'react';
import {Row, Col} from 'reactstrap';
import {Helmet} from 'react-helmet';
import QuickLinks from "../components/global/QuickLinks";
import Install1 from "../images/pages/Install1.png";
import Install2 from "../images/pages/Install2.png";

const Installation = () => {
    return (
        <div className={'contentContainer'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Design & Installation - SK Coldstores :: Gloucestershire Coldstore Experts</title>
                <meta name="description" content="Coldstore Design & Installation experts in the south west." />
                <link rel="canonical" href="https://skcoldstores.co.uk/install" />
            </Helmet>
            <Row>
                <Col>
                    <h1>
                        Design & Installation
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={7}>
                    <h2>
                        Over 20 years coldstore installation experience
                    </h2>
                    <p>We are in a unique position within the industry, as we can draw on knowledge from two disciplines, the refrigeration industry and the coldstore industry.</p>
                    <p>This means is when you invest in new refrigeration equipment, we build the most cost effective and efficient coldstores for the space available and application.</p>
                    <p>We can supply and fit everything from a small single chiller to a multi room distribution warehouse.</p>
                    <p>We can construct large or small rooms or temperature-controlled environments to protect from heat, cold, chemical or other external factors.</p>
                    <p>Along with a complete array of coldstore accessories to best fit the use and size of your coldstore</p>
                    <p>Our modular and bespoke coldstores are designed to operate for many years at the highest levels of thermal efficiency.</p>
                    <p>If shape, height, and specific finishes are required, SK can offer bespoke industrial/commercial coldstores that can be made to your exact requirements.  Whether it is for a Coldstore, Clean Room, Laboratory or Environmental Chamber.</p>
                    <p>Our standard panels are ideal for food preparation and production areas and come as standard with a hygienic white Foodsafe finish.</p>
                    <p>We can construct large or small rooms or temperature-controlled environments to protect from heat, cold, chemical or other external factors.</p>
                    <p>Mortuary Coldstore options vary from single door, 3-tier, to multi compartment suites. The range can include obese compartments. Fittings include free-standing racks and trays.</p>
                    <br />
                    <QuickLinks />
                </Col>
                <Col>
                    <img src={Install1} alt={'SK Cold Stores'} style={{width: '100%'}} />
                    <br /><br />
                    <img src={Install2} alt={'SK Cold Stores'} style={{width: '100%'}} />
                </Col>
            </Row>
        </div>
    )
}

export default Installation
import React from 'react';
import {Row, Col} from 'reactstrap';
import {Helmet} from 'react-helmet';
import QuickLinks from "../components/global/QuickLinks";
import Service1 from "../images/pages/Service1.png";
import Service2 from "../images/pages/Service2.png";

const Service = () => {
    return (
        <div className={'contentContainer'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Service & Maintenance - SK Coldstores :: Gloucestershire Coldstore Experts</title>
                <meta name="description" content="Coldstore service and repair experts in the south west." />
                <link rel="canonical" href="https://skcoldstores.co.uk/service" />
            </Helmet>
            <Row>
                <Col>
                    <h1>
                        Service & Maintenance
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={7}>
                    <h2>
                        Your local coldstore servicing & repair experts.
                    </h2>
                    <p>No matter how well you look after a coldstore, breakages and age will eventually get the better of them.</p>
                    <p>At SK we know how costly new coldstore can be, so we offer a maintenance service to refurbish the coldstore and ensure you get the best out of it. We will only decommission a coldstore when it becomes beyond economical repair.</p>
                    <p>We have a 25-point system on our survey for repairs.</p>
                    <p>We offer a thermal imaging survey and report to ensure the effectiveness of the insulation, joints, and door to best diagnose the issues and ensure the best solution.</p>
                    <p>We have a vast knowledge of coldstore design across the years and trained on all major global manufactures.</p>
                    <p>We can quote for repairs that will either be like for like, or a retrofit of new products, to extend the longevity of your coldstore and compliance to the Environmental Health Officer.</p>
                    <p>
                        <ul>
                            <li>Coldstore surveys and thermal imaging report</li>
                            <li>Refrigeration Monoblock replacement complete with infills</li>
                            <li>Resealing/Re-angling coldstores</li>
                            <li>Floor repairs/replacement both metalworks and insulation replacement</li>
                            <li>Hygienic relining and wall protection of coldstores</li>
                            <li>Coldstore Sliding door repairs including replacement doors and all door furniture</li>
                            <li>Coldstore hinged door repairs including replacement doors and all door furniture</li>
                            <li>Freezer Door heater system repairs and replacement</li>
                            <li>Coldstore food safe shelving</li>
                            <li>Scratch and dent panel repairs</li>
                            <li>Insulated panel repair/replacement</li>
                            <li>Panel protection guards</li>
                            <li>Temporary refrigeration solutions</li>
                            <li>We cover any coldstore issues as well refrigeration servicing at your request.</li>
                        </ul>
                    </p>
                    <br />
                    <QuickLinks />
                </Col>
                <Col>
                    <img src={Service1} alt={'SK Cold Stores'} style={{width: '100%'}} />
                    <br /><br />
                    <img src={Service2} alt={'SK Cold Stores'} style={{width: '100%'}} />
                </Col>
            </Row>
        </div>
    )
}

export default Service
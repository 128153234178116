import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import {Container, Row, Col} from 'reactstrap';
import MainRoutes from "./routes/MainRoutes";
import MainHeader from "./layouts/global/MainHeader";
import MainFooter from "./layouts/global/MainFooter";
import CallToAction1 from "./components/global/CallToAction1";

/*
    todo - add contact page details
    todo - add contect page map
    todo - add google analytics
    todo - update all images
    todo - accreditation images

 */


const App = () => {
  return (
      <Router basename={process.env.PUBLIC_URL}>
          <MainHeader />
          <CallToAction1/>
          <Container>
              <Row>
                  <Col>
                      <MainRoutes />
                  </Col>
              </Row>
              <MainFooter />
          </Container>
      </Router>
  );
}

export default App;

import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faPencilRuler, faPhone, faWrench} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const QuickLinks = () => {
    return (
        <>
            <h5>Quick Links</h5>
            <Button color={'primary'} tag={Link} to={'/'}><FontAwesomeIcon icon={faHome} /></Button> <Button color={'primary'} tag={Link} to={'/service'}><FontAwesomeIcon icon={faWrench} /> Service & Maintenance</Button> <Button color={'primary'} tag={Link} to={'/install'}><FontAwesomeIcon icon={faPencilRuler} /> Design & Installation</Button> <Button color={'primary'} tag={Link} to={'/contact'}><FontAwesomeIcon icon={faPhone} /> Contact Us</Button>
        </>
    )
}

export default QuickLinks